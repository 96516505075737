import { TextField, TextFieldProps } from '@mui/material'
import { useIntl } from 'react-intl'

import { Place } from 'components/icons'

import css from './MapGeocoder.module.css'

export const GeocoderTextField = ({ ...params }: TextFieldProps) => {
    const intl = useIntl()

    return (
        <TextField
            className={css.Input}
            {...params}
            placeholder={intl.formatMessage({
                description: 'Geocoder',
                defaultMessage: 'Search Places…',
            })}
            InputProps={{
                ...params.InputProps,
                startAdornment: <Place />,
            }}
            sx={{
                input: {
                    height: '40px',
                    '&::placeholder': {
                        color: '#50504e',
                        opacity: 1,
                    },
                },
            }}
        />
    )
}
