import { useEffect } from 'react'

import { MAP_LANDING } from '@avcan/constants/products/mixpanel'
import Footer, { Navigation } from '@avcan/crystal/components/footer/Footer'

import { RouterReady } from 'components/misc/RouterReady'
import useColumns from 'constants/menus/footer'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import Layout from 'layouts/map'
import * as Sources from 'layouts/map/sources/context'
import * as NextUtils from 'utils/next'
import { UserResearchPanelModal } from 'components/UserResearchPanel/UserResearchPanelModal'

export async function getStaticProps({ locale }) {
    return {
        props: {
            data: await Sources.getData(locale),
        },
        revalidate: 60, // in seconds
    }
}

export async function getStaticPaths() {
    const paths = [
        {
            params: {
                path: [],
            },
        },
    ]

    return {
        paths: NextUtils.internationalizeStaticPaths(paths),
        fallback: 'blocking',
    }
}

export default function Index({ data }) {
    const sendTrackEvent = useSendTrackEvent()
    const columns = useColumns()

    useEffect(() => {
        sendTrackEvent(MAP_LANDING, {})
    }, [sendTrackEvent])

    return (
        <Sources.Provider data={data}>
            <UserResearchPanelModal />
            <RouterReady>
                <Layout />
            </RouterReady>
            <Footer>
                <Navigation columns={columns} />
            </Footer>
        </Sources.Provider>
    )
}
